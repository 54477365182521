import { render, staticRenderFns } from "./AdminUserDetails.vue?vue&type=template&id=6cacb544&scoped=true&"
import script from "./AdminUserDetails.vue?vue&type=script&lang=ts&"
export * from "./AdminUserDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cacb544",
  null
  
)

export default component.exports