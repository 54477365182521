import { UserItem } from '@/models/User'
import { AddressItem, AddressParams } from '@/models/Address'
import { SocialLinkItem, SocialLinkParams } from '@/models/SocialLink'
import { BankAccountItem, BankAccountParams } from '@/models/BanckAccount'
import { ServiceItem, ServiceParams } from '@/models/Service'

export type RegisterProviderPayload = {
  firstName?: string,
  lastName?: string,
  email?: string,
  phoneNumber?:string,
  licences?: ProviderLicenceItem[],
  services?: ServiceParams[], // model is provider
  address?: AddressParams,
  businessName?: string,
  bankAccounts?: BankAccountParams[],
  socialLinks?: SocialLinkParams[],
  inService?: number,
  details?: any
}

export interface ProviderItem extends UserItem {
  licences: ProviderLicenceItem[],
  services: ServiceItem[], // model is provider
  address:AddressItem,
  phoneNumber:string,
  businessName: string,
  bankAccounts: BankAccountItem[],
  socialLinks: SocialLinkItem[],
  inService: number // 0 or 1,
  details?: any
}

export interface ProviderLicenceItem {
  slug?: string,
  title: string,
  mandatory: number,
  value:number,
}

export interface ProviderEquipmentItem {
  slug?: string,
  title?: string,
  mandatory?: number,
  value?:number,
}

export interface ProviderServiceItem {
  slug?: string,
  title: string,
  mandatory: number,
  value:number,
  minPrice?:number,
  maxPrice?:number,
}

export function defaultProviderLicences (): ProviderLicenceItem[] {
  return [
    {
      slug: 'driving_licence',
      title: 'I HAVE A VALID DRIVING LICENCE',
      mandatory: 1,
      value: 0

    },
    {
      slug: 'car_insurance',
      title: 'I HAVE A VALID CAR INSURANCE',
      mandatory: 1,
      value: 0

    },
    {
      slug: 'detailing_insurance',
      title: 'I AM INSURED TO PROVIDE DETAILING SERVICES',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'water_permit',
      title: 'I HAVE CLEAN WATER PERMIT',
      mandatory: 0,
      value: 0

    }
  ]
}

export function defaultProviderEquipment (): ProviderEquipmentItem[] {
  return [
    {
      slug: 'vacuum_cleaner',
      title: 'Vacuum Cleaner',
      mandatory: 1,
      value: 0

    },
    {
      slug: 'water_tank',
      title: 'Water Tank',
      mandatory: 1,
      value: 0

    },
    {
      slug: 'pressure_washer',
      title: 'Pressure Washer',
      mandatory: 0,
      value: 0
    }

  ]
}

export function defaultProviderServices (): ProviderServiceItem[] {
  return [
    {
      slug: 'water_based_wash',
      title: 'WATER BASED CAR WASH',
      mandatory: 1,
      value: 0

    },
    {
      slug: 'echo_friendly_wash',
      title: 'ECO-FRIENDLY CAR WASH',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'water_spot_removal',
      title: 'WATER SPOTS REMOVAL',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'scratch_removal',
      title: 'SCRATCH REMOVAL',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'leather_conditioning',
      title: 'LEATHER CONDITIONING',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'per_hair_removal',
      title: 'PET HAIR REMOVAL',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'engine_cleaning',
      title: 'ENGINE CLEANING',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'mold_removal',
      title: 'MOLD REMOVAL',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'headlight_restoration',
      title: 'HEADLIGHT RESTORATION',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'clay_bar_treatment',
      title: 'CLAY BAR TREATMENT',
      mandatory: 0,
      value: 0

    },
    {
      slug: 'baby_car_seat',
      title: 'BABY CAR SEAT',
      mandatory: 0,
      value: 0

    }

  ]
}
