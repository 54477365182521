
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { BCard, BCardBody, BCardHeader, BCardFooter, BFormGroup, BFormInput, BRow, BCol } from 'bootstrap-vue'
@Component({
  name: 'UserAccountTab',
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BFormGroup,
    BFormInput
  }

})
export default class UserAccountTab extends Vue {
  @Prop() private userItem!: string;
}
