



































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from '@/store/decorators'
import { ModelPayload } from '@/models/Payloads'
import { BAlert, BCard, BCol, BLink, BRow, BTab, BTabs } from 'bootstrap-vue'
import { CustomerModule } from '@/store/modules/customerModule'
import { CustomerItem } from '@/models/Customer'
import { UserRoleEnums } from '@/enums/User'
import UserAccountTab from './UserAccountTab.vue'
import UserProviderTab from './UserProviderTab.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

@Component({
  name: 'AdminUserDetails',
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BCol,

    UserAccountTab,
    UserProviderTab
  }

})
export default class AdminProviderDetails extends Vue {
  @Prop() private uid!: string;

  @Action(CustomerModule, 'fetchAdminCustomerUser') private fetchCustomer!: (payload: ModelPayload) => Promise<CustomerItem>
  // @Action(ProviderModule, 'fetchAdminProviderUser') private fetchProvider!: (payload: ModelPayload) => Promise<ProviderItem>

  private isShow :boolean = false
  private userRolesEnum = UserRoleEnums
  private customerItem :CustomerItem | null = null
  // private providerItem :ProviderItem | null = null

  private async created () {
    await this.loadCustomer()
    this.isShow = true
  }

  private async loadCustomer () {
    try {
      this.customerItem = await this.fetchCustomer({
        modelUid: this.uid
      })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: e.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
    }
  }

  @Watch('uid')
  private onUidChanged () {
    // console.log(this.uid)
    this.loadCustomer()
  }
}
