


















































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from '@/store/decorators'
import { ModelPayload } from '@/models/Payloads'
import { ProviderModule } from '@/store/modules/providerModule'
import { ProviderItem, defaultProviderLicences, ProviderLicenceItem } from '@/models/Provider'
import { BCard, BCardBody, BCardHeader, BCardFooter, BFormGroup, BFormInput, BRow, BCol, BFormTextarea, BFormCheckbox } from 'bootstrap-vue'
import { UserItem } from '@/models/User'
@Component({
  name: 'UserProviderTab',
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea
  }

})
export default class UserProviderTab extends Vue {
  @Prop() private userItem!: UserItem;
  @Action(ProviderModule, 'fetchAdminProviderUser') private fetchProvider!: (payload: ModelPayload) => Promise<ProviderItem>

  private providerLicences:ProviderLicenceItem[] = defaultProviderLicences()
  private providerItem :ProviderItem | null = null

  private created () {
    this.loadProvider()
  }

  private async loadProvider () {
    try {
      this.providerItem = await this.fetchProvider({
        modelUid: this.userItem.uid
      })
    } catch (error) {
      // console.error(error)
    }
  }
}
